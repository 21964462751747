import { endOfMonth, format, isEqual, parseISO, startOfMonth, subYears } from 'date-fns'
import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'
import { Controller } from 'stimulus'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default class extends Controller {
  static targets = [
    'dateOnly',
    'dateOnlyFuture',
    'dateSlashOnly',
    'timeOnly',
    'moduleDate',
    'dateRange',
    'taskDateRange',
    'dateRangeExcept',
    'dateRangeOnlyFuture',
    'dateRangeFilter',
    'dateMonthStats'
  ]
  static values = { url: String, model: String }
  datepickers = []
  locale = null

  connect() {
    if (window.I18n === 'fr') this.locale = French
    this.initializeDateOnlyTargets()
    this.initializeDateOnlyFuture()
    this.initializeDateSlashOnlyTargets()
    this.initializeTimeOnlyTargets()
    this.initializeModuleDateTargets()
    this.initializeDateRangeTargets()
    this.initializeDateRangeTargetsOnlyFuture()
    this.initializeDateRangeExceptDate()
    this.initializeDateRangeFilter()
    this.initializeTasksFilterDateRangeTargets()
    this.initializeDateMonthStats()
  }

  /**
   *
   * @param {Boolean} condition
   * @param {HTMLElement} targets
   * @param {Object} options
   */
  initializeCondition(targets, options = {}) {
    targets.forEach(t => {
      if (t._flatpickr) return
      this.datepickers.push(flatpickr(t, options))
    })
  }

  initializeDateOnlyTargets() {
    if (this.hasDateOnlyTarget) {
      this.initializeCondition(this.dateOnlyTargets, {
        altInput: true,
        enableTime: false,
        altInputClass: '',
        altFormat: 'j F Y',
        dateFormat: 'Y-m-d',
        locale: this.locale
      })
    }
  }

  initializeDateOnlyFuture() {
    if (this.hasDateOnlyFutureTarget) {
      this.initializeCondition(this.dateOnlyFutureTargets, {
        altInput: true,
        enableTime: false,
        altInputClass: '',
        altFormat: 'j F Y',
        dateFormat: 'Y-m-d',
        locale: this.locale,
        minDate: 'today'
      })
    }
  }

  initializeDateSlashOnlyTargets() {
    if (this.hasDateSlashOnlyTarget) {
      this.initializeCondition(this.dateSlashOnlyTargets, {
        altInput: true,
        enableTime: false,
        altInputClass: '',
        altFormat: 'd/m/Y',
        dateFormat: 'Y-m-d',
        locale: this.locale
      })
    }
  }

  initializeTimeOnlyTargets() {
    if (this.hasTimeOnlyTarget) {
      this.initializeCondition(this.timeOnlyTargets, {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
        minuteIncrement: 15,
        locale: this.locale
      })
    }
  }

  initializeDateRangeTargets() {
    if (this.hasDateRangeTarget) {
      this.dateRangeTargets.forEach(t => {
        if (t._flatpickr) return
        this.datepickers.push(
          flatpickr(t, {
            altInput: true,
            enableTime: false,
            mode: 'range',
            locale: this.locale,
            defaultDate: [t.dataset.startDate, t.dataset.endDate],
            altFormat: 'j F Y',
            dateFormat: 'Y-m-d'
          })
        )
      })
    }
  }

  initializeTasksFilterDateRangeTargets() {
    if (this.hasTaskDateRangeTarget) {
      this.taskDateRangeTargets.forEach(t => {
        if (t._flatpickr) return
        this.datepickers.push(
          flatpickr(t, {
            altInput: true,
            enableTime: false,
            mode: 'range',
            locale: this.locale,
            defaultDate: [t.dataset.startDate, t.dataset.endDate],
            altFormat: 'd/m/Y',
            dateFormat: 'd-m-Y',
            onReady: function (selectedDates, dateStr, instance) {
              instance.element.value = dateStr.replace(/to|au/, '-')
            },
            onClose: function (selectedDates, dateStr, instance) {
              instance.element.value = dateStr.replace(/to|au/, '-')
              const customEvent = new CustomEvent('flatpickr-hide')
              window.dispatchEvent(customEvent)
            }
          })
        )
      })
    }
  }

  initializeDateRangeTargetsOnlyFuture() {
    if (this.hasDateRangeOnlyFutureTarget) {
      this.dateRangeOnlyFutureTargets.forEach(t => {
        if (t._flatpickr) return
        let minDate = 'today'
        if (t.dataset.customMinDate === 'true') {
          minDate = t.dataset.startDate
        }
        this.datepickers.push(
          flatpickr(t, {
            altInput: true,
            enableTime: false,
            mode: 'range',
            locale: this.locale,
            defaultDate: [t.dataset.startDate, t.dataset.endDate],
            altFormat: 'd/m/Y',
            dateFormat: 'Y-m-d',
            minDate: minDate
          })
        )
      })
    }
  }

  initializeDateRangeFilter() {
    if (this.hasDateRangeFilterTarget) {
      console.log(this.hasDateRangeFilterTarget)
      this.dateRangeFilterTargets.forEach(t => {
        if (t._flatpickr) return
        const defaultStartDate = new Date(t.dataset.startDate)
        const defaultEndDate = new Date(t.dataset.endDate)
        this.datepickers.push(
          flatpickr(t, {
            altInput: true,
            enableTime: false,
            mode: 'range',
            locale: this.locale,
            defaultDate: [defaultStartDate, defaultEndDate],
            altFormat: 'd/m/Y',
            dateFormat: 'Y-m-d',
            onClose: function (selectedDates, dateStr, instance) {
              const startDate = instance.formatDate(selectedDates[0], 'Y-m-d')
              const endDate = instance.formatDate(selectedDates[1], 'Y-m-d')
              const defaultFormattedStartDate = instance.formatDate(defaultStartDate, 'Y-m-d')
              const defaultFormattedEndDate = instance.formatDate(defaultEndDate, 'Y-m-d')
              const sameDate = defaultFormattedStartDate === startDate && defaultFormattedEndDate === endDate
              if (sameDate) return
              const url = new URL(window.location)
              url.searchParams.delete('date_start')
              url.searchParams.delete('date_end')
              url.searchParams.set('date_start', instance.formatDate(selectedDates[0], 'Y-m-d'))
              url.searchParams.set('date_end', instance.formatDate(selectedDates[1], 'Y-m-d'))
              window.location = url
            }
          })
        )
      })
    }
  }

  initializeDateRangeExceptDate() {
    if (this.hasDateRangeExceptTarget) {
      this.dateRangeExceptTargets.forEach(t => {
        if (t._flatpickr) return
        this.datepickers.push(
          flatpickr(t, {
            altInput: true,
            enableTime: false,
            mode: 'range',
            locale: this.locale,
            altFormat: 'd/m/Y',
            dateFormat: 'Y-m-d',
            minDate: 'today',
            enable: [
              {
                from: t.dataset.startDate,
                to: t.dataset.endDate
              }
            ]
          })
        )
        console.log(t)
      })
    }
  }

  initializeModuleDateTargets() {
    if (this.hasModuleDateTarget) {
      this.initializeCondition(this.moduleDateTargets, {
        noCalendar: true,
        altInput: true,
        altFormat: 'Y',
        dateFormat: 'Y-m-d',
        locale: this.locale
      })
    }
  }

  modulePreviousDate() {
    if (!this.hasModuleDateTarget) return
    const currentDate = new Date(this.moduleDateTarget.value).getFullYear() - 1
    this.updateModuleBookings(currentDate)
  }

  moduleNextDate() {
    if (!this.hasModuleDateTarget) return
    const currentDate = new Date(this.moduleDateTarget.value).getFullYear() + 1
    this.updateModuleBookings(currentDate)
  }

  updateModuleBookings(date) {
    fetch(`${this.urlValue}?date=${date}`, {
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
      .then(res => res.json())
      .then(data => {
        document.querySelector(`#module-${this.modelValue}`).innerHTML = data.content
      })
  }

  initializeDateMonthStats() {
    if (this.hasDateMonthStatsTarget) {
      this.dateMonthStatsTargets.forEach(t => {
        this.datepickers.push(
          flatpickr(t, {
            altInput: true,
            altFormat: 'F Y',
            dateFormat: 'Y-m-d',
            locale: this.locale,
            defaultDate: new Date(),
            plugins: [new monthSelectPlugin()],
            maxDate: new Date(),
            minDate: subYears(new Date(), 1),
            onChange: function (selectedDates) {
              const date = format(selectedDates[0], 'yyyy-MM-dd')
              document.getElementById('date').value = date
              this.element.closest('form').requestSubmit()
            }
          })
        )
      })
    }
  }
}
