import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = ['chart']
  static values = {
    series: Array,
    annotations: Object
  }

  connect() {
    this.createChart()
    Chart.register(this.getMaxBarWidthPlugin());
  }

  createChart() {
    const ctx = this.chartTarget.getContext('2d')
    const annotations = this.annotationsValue
    const locale = I18n || 'en'

    new Chart(ctx, {
      type: 'bar',
      data: this.getChartData(),
      options: this.getChartOptions(locale, annotations)
    })
  }

  getChartData() {
    return {
      labels: this.seriesValue[0].data.map(item => item[0]),
      datasets: this.seriesValue.map(dataset => ({
        label: dataset.name,
        data: dataset.data.map(item => item[1]),
        stack: dataset.stack,
        backgroundColor: this.getBackgroundColor(dataset.name),
        borderColor: this.getBorderColor(dataset.name),
        borderWidth: 1
      }))
    }
  }

  getBackgroundColor(name) {
    return name === 'Communication Unique' ? 'rgba(107, 166, 103, 0.5)' : 'rgba(107, 61, 255, 0.5)'
  }

  getBorderColor(name) {
    return name === 'Communication Unique' ? 'rgba(107, 166, 103, 1)' : 'rgba(107, 61, 255, 1)'
  }

  getChartOptions(locale, annotations) {
    return {
      plugins: {
        tooltip: {
          callbacks: {
            title: tooltipItems => tooltipItems[0].label,
            afterBody: tooltipItems => this.getTooltipContent(tooltipItems, locale, annotations)
          }
        },
        datalabels: {
          display: true,
          formatter: (value, context) => this.getDataLabelsContent(context, annotations)
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      },
    };
  }

  // Ajust chart bar size
  getMaxBarWidthPlugin() {
    return {
      id: 'maxBarWidth',
      beforeDraw(chart) {
        const { ctx, scales: { x } } = chart;

        if (x && x.ticks && x.ticks.length > 0) {
          const maxBarWidth = 220;

          chart.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chart.getDatasetMeta(datasetIndex);

            if (meta.type === 'bar') {
              meta.data.forEach((bar, index) => {
                const barWidth = x.getPixelForValue(index + 1) - x.getPixelForValue(index);
                const newWidth = Math.min(barWidth, maxBarWidth);
                const barCenter = bar.x;

                bar.width = newWidth;
                bar.x = barCenter;
              });
            }
          });
        }
      }
    };
  }

  getTooltipContent(tooltipItems, locale, annotations) {
    const month = tooltipItems[0].label
    if (annotations[month]) {
      const { total_communication, total_invoiced } = annotations[month]
      if (tooltipItems[0].dataset.label != 'Communication Unique') {

        return locale === 'fr'
          ? [`SMS au réel envoyés : ${total_communication}`, `Total Facturé : ${total_invoiced}€`]
          : [`Total SMS: ${total_communication}`, `Total Price: ${total_invoiced}€`]
      } else {
        return locale === 'fr'
          ? [`SMS au réel envoyés : ${total_communication}`]
          : [`Total SMS: ${total_communication}`]
      }
    }
    return []
  }

  getDataLabelsContent(context, annotations) {
    const month = context.chart.data.labels[context.dataIndex]
    if (annotations[month]) {
      const { total_communication, total_invoiced } = annotations[month]
      return `${total_communication} SMS\n${total_invoiced}€`
    }
    return ''
  }
}
